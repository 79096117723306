import { useState } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';

export default function useRDStation() {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const subscribe = (
    {
      name = '',
      email = '',
      company = '',
      message = '',
      mobilePhone = '',
      conversionIdentifier = '',
      campaign = '',
      site = '',
      onSuccess = () => {},
      onError = () => {},
      onFinally = () => {},
    },
    successMessage,
  ) => {
    if (!email) {
      setError(true);
    } else {
      setLoading(true);
      setError(false);

      if (!window) return null;
      try {
        window.grecaptcha?.enterprise?.ready(async () => {
          const grecaptchaToken = await window.grecaptcha?.enterprise?.execute(
            process.env.NEXT_PUBLIC_GRECAPTCHA_SITE_KEY,
            { action: 'submit' },
          );
          axios
            .post('/api/rdstation/conversion', {
              name,
              email,
              company,
              mobilePhone,
              message,
              conversionIdentifier,
              campaign,
              site,
              grecaptchaToken,
            })
            .then(res => {
              setMessage(
                successMessage || `E-mail ${email} foi cadastrado com sucesso! Aguarde novidades.`,
              );

              setTimeout(() => {
                setMessage('');
              }, 5000);

              onSuccess(res?.data);
            })
            .catch(err => {
              setError(true);
              setMessage(err.response?.data?.message);

              onError(err);
            })
            .then(() => {
              setLoading(false);
              onFinally();
            });
        });
      } catch {
        enqueueSnackbar('Erro ao validar formulário', { variant: 'error' });
      }
    }
  };

  return { message, error, loading, subscribe, setError, setMessage, setLoading };
}
