import { Typography, Grid, TextField, Alert } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';

import { useFormik } from 'formik';

import useRDStation from '@/src/lib/rdstation/useRDStation';
import { MaskedPhoneNumberWithCode } from '@/src/lib/utils/masks';
import { Yup } from '@/src/lib/yup';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const ContactForm = () => {
  const classes = useStyles();

  const { message, error, loading, subscribe } = useRDStation();

  const formik = useFormik({
    initialValues: {
      mobilePhone: '',
      name: '',
      email: '',
      company: '',
      message: '',
    },
    validationSchema: Yup.object({
      mobilePhone: Yup.string()
        .min(14, 'Informe o número corretamente')
        .max(15, 'Informe o número corretamente')
        .label('Celular')
        .required('Informe o número do seu celular'),
      name: Yup.string().label('Nome').required('É necessário informar o nome do contato'),
      email: Yup.string().label('E-mail').email().required('Preencha um e-mail válido'),
      company: Yup.string()
        .label('Empresa ou nome da escola')
        .required('Informe o nome da sua empresa ou escola'),
      message: Yup.string().label('Mensagem').required('A sua mensagem é necessária'),
    }),
    onSubmit: async (values, formikBag) =>
      subscribe(
        {
          ...values,
          conversionIdentifier: 'home',
          campaign: 'landingpage',
          site: 'www.eskolare.com',
          onSuccess: () => formikBag.resetForm(),
        },
        'Dados enviados. Em breve entraremos em contato!',
      ),
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            <strong>Quer criar a sua loja online?</strong>
          </Typography>
          <Typography color="textSecondary" align="center">
            Nossos especilalistas entrarão em contato com você!
          </Typography>
          <Typography color="textSecondary" align="center">
            Preencha com seus dados e comece a vender online em até 5 dias úteis!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Nome completo"
            label="Nome *"
            variant="outlined"
            size="medium"
            fullWidth
            type="text"
            disabled={loading}
            {...formik.getFieldProps('name')}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Celular"
            label="Celular *"
            variant="outlined"
            size="medium"
            fullWidth
            type="tel"
            InputProps={{ inputComponent: MaskedPhoneNumberWithCode }}
            disabled={loading}
            {...formik.getFieldProps('mobilePhone')}
            error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
            helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            placeholder="E-mail"
            label="E-mail *"
            variant="outlined"
            size="medium"
            fullWidth
            type="email"
            disabled={loading}
            {...formik.getFieldProps('email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Empresa ou nome da escola"
            label="Empresa / Escola *"
            variant="outlined"
            size="medium"
            fullWidth
            type="text"
            disabled={loading}
            {...formik.getFieldProps('company')}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Mensagem"
            label="Mensagem *"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            disabled={loading}
            {...formik.getFieldProps('message')}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Campos marcados com * são obrigatórios.
          </Typography>
          <LoadingButton
            loading={loading}
            size="large"
            variant="contained"
            type="submit"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || loading}>
            Enviar
          </LoadingButton>
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Alert severity={error ? 'error' : 'info'}>{message}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ContactForm;
